import React from 'react'
import Tab from "../tabs"
import Watch from "../Auction Won/Watchlist";

function Favourit() {
  return (
    <>
                <div className="py-6 px-5 bg-white bg-opacity-75">
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                  <h1 className="text-2xl font-semibold text-gray-900">Favourites</h1>
                           </div>
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                    <div className="py-4">
                      <div className="h-96 ">
                      <Watch/>
                      </div>
                    </div>
                  </div>
                </div>

    </>
  )
}

export default Favourit
