/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import "./component.css";
import { BiSearchAlt } from "react-icons/bi";
import { GrAdd, GrFormClose } from "react-icons/gr";
import FeedbackContext from "../../context/cmscontext";
import { useContext } from "react";
// header
export const Header = (props) => {
  const { handleDrawer, clearAll, totalAppliedFilters } = props;
  const { feedback, isLoading } = useContext(FeedbackContext);
  let auction_page = feedback.auction_page;
  return (
    <div className="side_bar_header_main_cont w-100 ">
      <span className="side_bar_header_title  ">{auction_page?.filter_title}</span>
      <span>
        <button onClick={clearAll} className="side_bar_header_title_2 mt-2 ">
          {/* {totalAppliedFilters ? `Clear all(${totalAppliedFilters})` : null} */}
          {totalAppliedFilters ? `Clear All` : null}
        </button>
        {handleDrawer ? (
          <GrFormClose
            onClick={handleDrawer}
            className="searchableListPicker_remove_selected "
          />
        ) : null}
      </span>
    </div>
  );
};
// search able list picker
export const SearchableListPicker = (props) => {
  const { data, callBack, value } = props;
  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(value);
  const [filteredData, setFilteredData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(4);
  const renderList = filteredData.length > 0 ? filteredData : query ? [] : data;
  function expandedHandler() {
    if (itemsToShow === 4) {
      setItemsToShow(renderList.length);
      setExpanded(true);
    } else {
      setItemsToShow(4);
      setExpanded(false);
    }
  }
  const handleFiltration = async () => {
    const query_ = query.toLowerCase();
    const filteredArr = data.filter((item) => {
      let item_ = item.toLowerCase();
      return item_.includes(query_);
    });
    setFilteredData(filteredArr);
  };
  useEffect(() => {
    if (query) {
      handleFiltration();
    } else {
      setFilteredData([]);
    }
  }, [query]);
  const setSelectedOptionHandler = (e) => {
    const e_ = e === selectedOption ? "" : e;
    callBack(e_);
    setSelectedOption(e_);
  };
  return (
    <div className="searchableListPicker_main_cont ">
      <div className="searchableListPicker_input_wrapper ">
        <label className="searchableListPicker_search_icon_wrapper">
          <BiSearchAlt className="searchableListPicker_search_icon " />
        </label>
        <input
          id="searchableListPicker_input"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search"
          className="searchableListPicker_input"
        />
      </div>
      {selectedOption ? (
        <div className=" d-flex justify-content-between align-items-center p-1 w-100 ">
          <span className="searchableListPicker_selected_item_text">
            selected: {selectedOption}
          </span>
          <GrFormClose
            onClick={() => setSelectedOptionHandler("")}
            className="searchableListPicker_remove_selected "
          />
        </div>
      ) : null}
      <div className="display_data_list_cont" id="display_data_list_cont">
        {React.Children.toArray(renderList.slice(0, itemsToShow).map((item, index) => {
          const itemSelected = item === selectedOption ? true : false;
          return (
            <button
              onClick={() => setSelectedOptionHandler(item)}
              className="display_data_list_item_cont "
              key={item?.id}
              style={{
                borderBottom:
                  index === renderList.length - 1
                    ? "none"
                    : `0.5px solid var(--infoPlus)`,
              }}
            >
              <span
                className="display_data_list_item_icon"
                style={{
                  background: itemSelected
                    ? "var(--success)"
                    : "var(--infoPlus)",
                }}
              >
                {itemSelected ? "✔" : item[0]}
              </span>
              <span className="display_data_list_item_text">{item}</span>
            </button>
          );
        }))}
      </div>
      <button onClick={expandedHandler} className="display_data_show_more">
        <span
          className="mr-1"
          style={{ fontWeight: "600", fontSize: "1.5rem" }}
        >
          {!expanded ? "+" : "-"}
        </span>
        {!expanded ? "Show all" : "Show less"}
      </button>
    </div>
  );
};
// range picker and its input component
export const DataListPicker = (props) => {
  const { callBack, value, parentCallBackHandler } = props;
  return (
    <div className=" w-100 dataListPicker_main_cont ">
      <input
        onBlur={parentCallBackHandler}
        value={value}
        className="dataListPicker_input "
        onChange={(e) => callBack(e.target.value)}
        placeholder="select"
      />
    </div>
  );
};

export const SearchAbleRangePicker = (props) => {
  const { minCallBack, maxCallBack, value } = props;
  const [fromValue, setFromValue] = useState(value.min);
  const [toValue, setToValue] = useState(value.max);
  const callBackHandler = () => {
    minCallBack(fromValue);
    maxCallBack(toValue);
  };
  return (
    <div className="w-100">
      {/* row 1 */}
      <div className="d-flex flex-row align-items-center justify-content-between w-100 searchAbleRangePicker_row  ">
        <div className="searchAbleRangePicker_col_1">From</div>
        <div className="searchAbleRangePicker_col_2">
          <DataListPicker
            parentCallBackHandler={callBackHandler}
            value={fromValue}
            callBack={setFromValue}
          />
        </div>
      </div>
      {/* row 2 */}
      <div className="d-flex flex-row align-items-center justify-content-between w-100 searchAbleRangePicker_row  ">
        <div className="searchAbleRangePicker_col_1">to</div>
        <div className="searchAbleRangePicker_col_2">
          <DataListPicker
            parentCallBackHandler={callBackHandler}
            value={toValue}
            callBack={setToValue}
          />
        </div>
      </div>
    </div>
  );
};

// check box list and its component
export const LabeledCheckBox = (props) => {
  const { data, id, handleCheckBox,default_value } = props;

  return (
    <div>
      <label className="d-flex align-items-center labeledCheckBox_label">
        <input
          onChange={(e)=>handleCheckBox(id,e.target.checked,data)}
          className="labeledCheckBox_input mr-2"
          type="checkbox"
          checked={default_value}
        />
        {data}
      </label>
    </div>
  );
};
export const CheckBoxList = (props) => {
  const { data, id, handleCheckBox,default_value,to_check } = props;

  return (
    
    <div>
      {React.Children.toArray(data.map((item) => (
        
        <LabeledCheckBox key={item?.id} default_value={default_value?default_value.includes(to_check?to_check:item.replace("KM's","").trim()):false} data={item} id={id} handleCheckBox={handleCheckBox}/>
      )))
      }
    </div>
  );
};