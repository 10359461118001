import React, { useEffect, useState } from "react";
import { PUT, POST, DELETE, GET } from "../constant/apiMethods";
import { MdDeleteForever } from "react-icons/md";
import Labels from "../services/apiLabels";
import { ADD_VEHICLE_AUCTION, VEHICLE_LIST } from "../constant";
import { connect } from "react-redux";
import { AiOutlineEdit } from "react-icons/ai";
import { ImHammer2 } from "react-icons/im";
import carimg from "../assets/images.jpg";
import { AiFillCamera } from "react-icons/ai";
import config from "../config";
import { FaEuroSign } from "react-icons/fa";
export const API_ENDPOINT = (label) => {
  return `${config.baseUrl}${label}`;
};
const Table = ({
  myVehiclesList,
  getAllCars,
  setisEdit,
  setSelectedTabIdx,
  addvechielAuction,
  updatevechielAuction,
  getCarWiners,
  user_id,
  updatedVehicle
}) => {
  const edit = (id) => {
    setisEdit(id);
    setSelectedTabIdx(1);
  };
  const [showModal, setShowModal] = React.useState(false);
  const [price, setPrice] = React.useState("");
  const [opening_price, setopening_price] = React.useState("");
  const [cur_price, setcur_price] = React.useState("");
  const [lowest_price, setlowest_price] = React.useState("");
  const [increment_value, setincrement_value] = React.useState("");
  const [start_date, setstart_date] = React.useState("");
  const [end_date, setend_date] = React.useState("");
  const [status, setstatus] = React.useState(false);
  const [buynowStatus, setBuynowStatus] = React.useState(false);
  const [radio, setRadio] = React.useState("auction");
  const [product_id, setproduct_id] = React.useState();
  const [auctionData, setauctionData] = React.useState(null);
  const [buynowData, setBuynowData] = React.useState(null);
  const [startDateSelected, setStartDateSelected] = useState(false);
  const [auctionArray, setAuctionArray] = useState(null)
  const [radioVisible, setRadioVisble] = useState(true)
  console.log(updatedVehicle)
  const handleStartDateChange = (event) => {
    setStartDateSelected(true);
  };

  console.log("my vehicle list", myVehiclesList)
  const convertTime = (date) => {
    const dateString = date;
    const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
    const localDate = new Date(dateString);
    const utcDate = new Date(localDate.getTime() + userOffset);
    return utcDate;
  };
  const Delete = (id) => {
    const payload = {
      label: `${Labels.delvechiel}${id}/`,
      method: DELETE,

      hideOverlayLoader: true,
    };
    fetchData(payload);
  };
  function fetchData(payload) {
    const { label, data, method, header } = payload;

    const parameters = {
      method,
      body: data,
    };
    fetch(API_ENDPOINT(label), parameters)
      .then((response) => {
        getAllCars();
        console.log("response-----------", response);
      })
      .then((json) => {
        return json;
      });
  }

  function formatDate(timestamp) {
    const dateObj = new Date(timestamp);

    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const hours = ("0" + dateObj.getHours()).slice(-2);
    const minutes = ("0" + dateObj.getMinutes()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  console.log(status, "start_date--------------", auctionData);
  useEffect(() => {
    if (auctionData) {
      console.log("its working correctyly",auctionData)
      setopening_price(auctionData.opening_price);
      setcur_price(auctionData.cur_price);
      setlowest_price(auctionData.lowest_price);
      setincrement_value(auctionData.increment_value);
      setstart_date(formatDate(convertTime(auctionData.start_date)));
      setend_date(formatDate(convertTime(auctionData.end_date)));
      setstatus(auctionData.status);
    } else {
      setopening_price("");
      setcur_price("");
      setlowest_price("");
      setincrement_value("");
      setstart_date("");
      setend_date("");
      setstatus(false);
    }
  }, [auctionData]);
  console.log(buynowData)
  useEffect(() => {
    if (buynowData) {
      console.log("buynow useeffect working",buynowData)
      setPrice(buynowData?.buy_now_price)
      setBuynowStatus(buynowData.buy_now)
    } else {
      setPrice("")
      setBuynowStatus(false)
    }
  }, [buynowData])
  const handleSubmit = (e) => {
    e.preventDefault();
    if (auctionData) {
      const data = {
        created_by: JSON.parse(localStorage.getItem("WFAC_localstorage_auth"))
          .id,
        product_id: product_id,
        opening_price: opening_price,
        cur_price: cur_price,
        lowest_price: lowest_price,
        increment_value: increment_value,
        start_date: start_date,
        end_date: end_date,
        status: status,
      };
      if (radio === "auction") {
        const type = "auction"
        updatevechielAuction(data, auctionData.id, type);
        setShowModal(false);
        setopening_price("");
        setcur_price("");
        setlowest_price("");
        setincrement_value("");
        setstart_date("");
        setend_date("");
        setstatus(false);
      }
    } else {
      const data = {
        created_by: JSON.parse(localStorage.getItem("WFAC_localstorage_auth"))
          .id,
        product_id: product_id,
        opening_price: opening_price,
        cur_price: opening_price,
        lowest_price: lowest_price,
        increment_value: increment_value,
        start_date: start_date,
        end_date: end_date,
        status: status,
      };
      const type = "auction"
      addvechielAuction(data, type);
      setShowModal(false);
      setopening_price("");
      setcur_price("");
      setlowest_price("");
      setincrement_value("");
      setstart_date("");
      setend_date("");
      setstatus(false);
    }
    if (buynowData) {
      const buyData = {
        buy_now: buynowStatus,
        buy_now_price: price
      }
      console.log(buynowData)
      if (radio === "buynow") {
        const type = "buy_now"
        updatevechielAuction(buyData, buynowData.id, type);
        setShowModal(false);
        setPrice("")
        setBuynowStatus(false)
        setRadio("auction")
      }
    } else {
      const data = {
        buy_now: buynowStatus,
        buy_now_price: price
      };
      const type = "buy_now"
      addvechielAuction(data, type);
      setShowModal(false);
      setPrice("")
      setBuynowStatus(false)
    }
  };
  useEffect(()=>{
    if(updatedVehicle){
      getCarWiners(user_id)
    }
  },[updatedVehicle])
  return (
    <div className="flex  flex-col">
      {showModal ? (
        <>
          <div className="justify-center mt-20 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <form onSubmit={handleSubmit}>
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="relative p-6 flex-auto">
                    <div className="row">
                  {radioVisible &&   <div className="col-sm-12 flex items-baseline justify-center my-3">
                        <input
                          type="radio"
                          id="auction"
                          name="auction"
                          value="auction"
                          onChange={(e) => {
                            setRadio(e.target.value);
                          }}
                          checked={radio === "auction"}
                        />
                        <label for="auction" className="mx-2">
                          Auction
                        </label>
                        <input
                          type="radio"
                          id="buynow"
                          name="buynow"
                          value="buynow"
                          onChange={(e) => {
                            setRadio(e.target.value);
                          }}
                          checked={radio === "buynow"}
                        />
                        <label for="buynow" className="mx-2">
                          Buynow
                        </label>
                      </div>}
                      {radio === "buynow" &&
                        <>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="name-f">Price</label>
                            <input
                              type="number"
                              className="form-control"
                              name="price"
                              id="price"
                              value={price}
                              placeholder="Enter Price"
                              required
                              onChange={(e) => {
                                setPrice(e.target.value);
                              }}
                            />
                          </div>
                          <div className={`col-sm-6 form-group d-flex items-baseline ${radio === "buynow" && "mt-[1.8rem]"}`}>
                            <label for="vehicle2" className="mr-2">
                              Status
                            </label>
                            <br></br>
                            <input
                              type="checkbox"
                              id="vehicle2"
                              name="vehicle2"
                              onChange={(e) => {
                                setBuynowStatus(e.target.checked);
                              }}
                              checked={buynowStatus}
                            />
                          </div>
                        </>
                      }
                      {radio === "auction" &&
                        <>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="name-f">Opening Price</label>
                            <input
                              disabled={auctionData}
                              type="number"
                              className="form-control"
                              name="name"
                              id="name-f"
                              value={opening_price}
                              placeholder="Enter Opening Price"
                              required
                              onChange={(e) => {
                                setopening_price(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="Postcode">Current Price</label>
                            <input
                              type="number"
                              disabled
                              className="form-control"
                              name="postcode"
                              id="postcode"
                              value={opening_price}
                              placeholder="Enter Current Price"
                              onChange={(e) => {
                                setcur_price(e.target.value);
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="price">Reserved Price</label>
                            <input
                              type="number"
                              className="form-control"
                              name="price"
                              id="price"
                              value={lowest_price}
                              placeholder="Reserved Price"
                              onChange={(e) => {
                                setlowest_price(e.target.value);
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="price">Increment Value</label>
                            <input
                              type="number"
                              className="form-control"
                              name="price"
                              id="price"
                              value={increment_value}
                              placeholder="Increment Value"
                              onChange={(e) => {
                                setincrement_value(e.target.value);
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="price">Start Date</label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              name="price"
                              id="price"
                              value={start_date}
                              placeholder="Enter Start Date"
                              onChange={(e) => {
                                setstart_date(e.target.value);
                                handleStartDateChange();
                              }}
                              required
                            />
                          </div>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="price">End Date</label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              name="price"
                              id="price"
                              value={end_date}
                              placeholder="Enter End Date"
                              onChange={(e) => {
                                const newEndDate = e.target.value;
                                if (start_date && newEndDate < start_date) {
                                  setend_date("");
                                  return;
                                }
                                setend_date(newEndDate);
                              }}
                              disabled={!startDateSelected}
                              min={start_date}
                              required
                            />
                          </div>
                          <div className={`col-sm-6 form-group d-flex items-baseline ${radio === "buynow" && "mt-[1.8rem]"}`}>
                            <label for="vehicle1" className="mr-2">
                              Status
                            </label>
                            <br></br>
                            <input
                              type="checkbox"
                              id="vehicle1"
                              name="vehicle1"
                              onChange={(e) => {
                                setstatus(e.target.checked);
                              }}
                              checked={status}
                            />
                          </div>
                        </>
                      }
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                        setStartDateSelected(false);
                        setopening_price("");
                        setcur_price("");
                        setlowest_price("");
                        setincrement_value("");
                        setstart_date("");
                        setend_date("");
                        setstatus(false);
                        setauctionData(null);
                        setBuynowData(null)
                        setRadio("auction")
                        setPrice("")
                        setBuynowStatus(false)
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border border-gray-200 rounded-lg">
            <table className="min-w-full divide-y-2 divide-gray-200 ">
              <thead className="active-color text-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-2.5 text-left text-base  tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2.5 text-left text-base  tracking-wider"
                  >
                    Vehicle
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-2.5 text-left text-base  tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2.5 text-left text-base  tracking-wider"
                  >
                    Image
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2.5 text-left text-base  tracking-wider whitespace-nowrap"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 ">
                {myVehiclesList
                  .map((item, i) => (
                    <tr key={i}>
                      <td className=" px-6 py-3.5 whitespace-nowrap ">
                        {item.name}
                      </td>
                      <td className=" px-6 py-3.5 whitespace-nowrap  ">
                        {item.brand_name
                          ? `${item.brand_name}${item.model_name ? `-${item.model_name}` : ""
                          }${item.fuel_type ? `-${item.fuel_type}` : ""}`
                          : "-"}
                        {/* {item.model_name? `${item.model_name}-`:""}
                         */}
                      </td>

                      <td className=" px-6 py-3.5 whitespace-nowrap  ">
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <FaEuroSign size={15} />{" "}
                          {item.price ? item.price : "-"}
                        </span>
                      </td>
                      <td className=" px-6 py-3.5 whitespace-nowrap  ">
                        <img
                          style={{ width: 50, height: 50 }}
                          src={item.image_method ? item.image_method : carimg}
                        ></img>
                      </td>
                      <td className=" px-6 py-3.5 whitespace-nowrap  ">
                        <div className="d-flex" style={{ cursor: "pointer" }}>
                          {item.allow_delete && (
                            <MdDeleteForever
                              onClick={() => {
                                Delete(item.id);
                              }}
                              size={20}
                              className="mr-2"
                            />
                          )}
                          <AiOutlineEdit
                            onClick={() => {
                              edit(item.id);
                            }}
                            className="mr-2"
                            size={20}
                          />
                          <ImHammer2
                            onClick={() => {
                              if (item.auction.length > 0) {
                                setauctionData(item.auction[0]);
                                setproduct_id(item.id);
                                setShowModal(true);
                                setRadioVisble(false)
                                setRadio("auction")
                              } else {
                                setproduct_id(item.id);
                                setShowModal(true);
                                if(item.buy_now){
                                  setRadioVisble(false)
                                  setRadio("buynow")
                                }
                              }
                              setBuynowData({ buy_now_price: item.buy_now_price, buy_now: item.buy_now, id:item.id })
                            }}
                            className="mr-2"
                            size={20}
                          />
                          <AiFillCamera
                            onClick={() => {
                              window.open(
                                `https://inspection.sellanywheel.com/dealerinspection?id=${item.inspect_id}`
                              );
                            }}
                            size={20}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.auth.id,
  myVehiclesList: state?.myVehicles?.data,
  updatedVehicle:state?.myVehicles?.updateData
});

const mapDispatchToProps = (dispatch) => ({
  // get product list
  addvechielAuction: (data, type) =>
    dispatch({
      type: ADD_VEHICLE_AUCTION,
      payload: {
        type: ADD_VEHICLE_AUCTION,
        data,
        label: `${Labels.addvechielAuction}?type=${type}`,
        method: POST,
        hideOverlayLoader: true,
      },
    }),
  updatevechielAuction: (data, id, type) =>
    dispatch({
      type: ADD_VEHICLE_AUCTION,
      payload: {
        type: ADD_VEHICLE_AUCTION,
        data,
        label: `${Labels.updatevechielAuction}${id}/?type=${type}`,
        method: PUT,
        hideOverlayLoader: true,
      },
    }),
    getCarWiners: (id) =>
    dispatch({
      type: VEHICLE_LIST,
      payload: {
        type: VEHICLE_LIST,
        label: `${Labels.vechiellist}${id}/`,
        method: GET,

        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
