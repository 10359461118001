import React, { useState, useEffect } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { CLOSE_WISH_LIST, CREATE_WISH_LIST } from "../../constant";
import { GET, POST } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { useLocation } from "react-router-dom";
import product from "../../reducers/product";
function WishListButton({
  product_index,
  product_code_wish,
  createWishList,
  user_id,
  product_auction,
  closeWishList,
  bidingExpired,
  product_buy,
}) {
  const [show, setshow] = useState(product_code_wish?.watchlist);
  const location = useLocation();
  useEffect(() => {
    console.log("product code wish ===>> ", product_code_wish);
    setshow(product_code_wish?.watchlist);
  }, [product_buy, product]);
  const creat_wish_list = () => {
    setshow(true);
    createWishList({
      user: user_id,
      product: product_code_wish.id,
      product_index: product_index,
    });
  };
  const close_wish_list = () => {
    setshow(false);
    closeWishList({
      user: user_id,
      product: product_code_wish.id,
      product_index: product_index,
    });
  };
  return (
    !bidingExpired && (
      <button className="wish_list_main_cont">
        {show ? (
          <AiFillHeart className="wish_list_icon" onClick={close_wish_list} />
        ) : (
          <AiOutlineHeart
            className="wish_list_icon"
            onClick={creat_wish_list}
          />
        )}
      </button>
    )
  );
}
const mapStateToProps = (state) => ({
  product_auction: state.product.data,
  product_buy: state.product.databuy,
  user_id: state.auth.id,
  filtersQuery: state.filters.queryString_,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  createWishList: (data) =>
    dispatch({
      type: CREATE_WISH_LIST,
      payload: {
        type: CREATE_WISH_LIST,
        method: POST,
        data,
        label: Labels.create_wish_list,
        hideOverlayLoader: true,
      },
    }),
  // dislike product list
  closeWishList: (data) =>
    dispatch({
      type: CLOSE_WISH_LIST,
      payload: {
        type: CLOSE_WISH_LIST,
        method: POST,
        data,
        label: Labels.close_wish_list,
        hideOverlayLoader: true,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(WishListButton);