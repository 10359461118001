import React, { useState, useEffect } from "react";
import "./style.css";
import Table from "./Table";
import { VEHICLE_LIST } from "../constant";
import { GET } from "../constant/apiMethods";
import { connect } from "react-redux";
import AddWheel from "../components/AddWheel/AddWheel";
import Labels from "../services/apiLabels";
import { height } from "@mui/system";


// Example usage in the component


const Tabs = ({ getCarWiners, myVehiclesList, user_id }) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);
  const [isEdit, setisEdit] = useState(null);

  const tabs = [
    { name: "All Vehicle", current: false },
    { name: isEdit ? "Update Vehicle" : "Add Vehicle", current: true },
  ];

  useEffect(() => {
    getAllCars();
  }, []);

  const getAllCars = () => {
    const id = JSON.parse(localStorage.getItem("WFAC_localstorage_auth")).id;
    getCarWiners(id);
  };

  console.log("myVehiclesList\n", myVehiclesList);

  return (
    <>
      <style jsx="true">{`
        .table-div-background {
          display: flex;
          flex-direction: column;
          height: 68vh;
        }

        .nav-tabs {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
        }

        .tabs-class {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-weight: bold;
          background: none;
          border: none;
          cursor: pointer;
          transition: all 0.2s ease;
        }

        .active-tab-class {
          font-size: 1vw;
          color: #178970 !important;
          background: none;
          border-bottom: 3px solid #178970;
          transition: all 0.2s ease;
        }

        @media only screen and (max-width: 1000px) {
          .table-div-background {
            margin-right: 2rem !important;
          }

          .tabs-class {
            font-size: 1.3vw;
          }

          .active-tab-class {
            font-size: 1.3vw;
          }
        }

        @media only screen and (max-width: 760px) {
          .table-div-background {
            margin-right: none !important;
          }
        }
      `}</style>

      <div className="table-div-background">
        <nav className="nav-tabs" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <div className="w-100 d-flex justify-content-center align-items-center"
            style={{
              height: "40px",
              ...(index !== selectedTabIdx 
                ? { background: "gainsboro" } 
                : { border: "solid 1px gainsboro", borderBottom: "none" }
              )
            }}                 
            onClick={() => {
              setSelectedTabIdx(index);
              setisEdit(null);
            }}   
            >
              <button
                key={index}
                className={`${
                  index === selectedTabIdx ? "active-tab-class" : ""
                } tabs-class`}
              >
                {index === 0 ? `${tab.name} (${myVehiclesList?.length})` : `${tab.name} (0)`}
              </button>
            </div>
          ))}
        </nav>
        {selectedTabIdx === 0 && (
          <div className="my-10 overflow-y-auto container-fluid">
            <Table
              setSelectedTabIdx={setSelectedTabIdx}
              setisEdit={setisEdit}
              getAllCars={getAllCars}
              myVehiclesList={myVehiclesList}
              columns={["Name", "Vehicle", "Price", "Image", "Action"]}
            />
          </div>
        )}
        {selectedTabIdx === 1 && (
          <div className="my-10 overflow-y-auto container-fluid">
            <AddWheel
              setisEdit={setisEdit}
              isEdit={isEdit}
              setSelectedTabIdx={setSelectedTabIdx}
              getAllCars={getAllCars}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user_id: state?.auth?.id,
  myVehiclesList: state?.myVehicles?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getCarWiners: (id) =>
    dispatch({
      type: VEHICLE_LIST,
      payload: {
        type: VEHICLE_LIST,
        label: `${Labels.vechiellist}${id}/`,
        method: GET,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);